import React from 'react'
import { MDBRow, MDBCol, MDBBtn } from 'mdbreact'
import { GatsbyImage } from 'gatsby-plugin-image'
import Showdown from 'showdown'

class listPartners extends React.Component {

  render() {
    const converter = new Showdown.Converter()  
    return (
      <>
          {this.props.data.feature.map((features, index) => (
            <MDBRow className="about-item">
              <MDBCol lg="3" key={index}>
                <GatsbyImage image={features.image.childImageSharp.gatsbyImageData} alt="company logo" className="mb-3 mb-lg-0" />
              </MDBCol>
              <MDBCol lg="9" className="pl-lg-5">
                <div>
                  <h3 className="font-alt font-w-700 letter-spacing-1 mb-3 title-xs-medium title-large">
                    {features.title}
                  </h3>
                  <p className="font-alt font-w-700 letter-spacing-1 mb-3 title-xs-medium title-medium">{features.subtitle}</p>
                  <div className="mt-3 font-w-400 text-medium divlink" style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{__html: converter.makeHtml(features.description)}} />
                </div>
              </MDBCol>
              </MDBRow>
          ))}
      </>
    )
  }
}

export default listPartners
